import React from "react"
import styled from "styled-components"
import withLayout from "../../layout"
import {FormattedMessage, injectIntl} from "react-intl"
import SimpleStats from "../../components/UI/SimpleStats"
import Image from "../../components/UI/Image"
import {Heading, Paragraph} from "../../components/UI/Typography"
import DropBottom from '../../images/case_studies/bysmart/DropBottom.svg'
import DropUp from '../../images/case_studies/bysmart/DropUp.svg'
import HeroCaseStudy from "../../components/HeroCaseStudy"
import LottieController from "../../components/UI/LottieController"
import {useInView} from "react-intersection-observer"
import DropBg from "../../components/UI/DropBg"
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg'
import DropBig2 from '../../images/case_studies/acea/DropTopLeftM.svg'
import Result1 from "../../components/UI/Lottie/Occhio.json";
import Result2 from '../../images/case_studies/translated/Icona Tachimetro.json';
import SEO from "../../components/SEO"
import {Fade} from 'react-reveal'
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage"
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory"
import SectionTitle from "../../components/CaseStudy/SectionTitle"
import Results from "../../components/CaseStudy/Results"
import VideoBysmart from "../../images/case_studies/bysmart/banner_video.mp4"
import BodyGif from '../../images/case_studies/bysmart/gif_mobile.gif'
import BKGImg from "../../images/case_studies/bysmart/banner.jpg"
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const FullWidthVideo = styled.div`
    background: url(${(props) => props.image}) no-repeat 70% center;
    background-size: cover;
    width: 100%;
    height: 600px;
`

const BysmartCaseStudy = ({intl}) => {
    const [graphView1, inViewG1] = useInView()
    const [graphView2, inViewG2] = useInView()
    const keywords = intl.formatMessage({id: 'bysmart.meta_keywords'}).split(',') || []

    return (
        <>
            <SEO
                title={intl.formatMessage({id: 'bysmart.meta_title'})}
                description={intl.formatMessage({id: 'bysmart.meta_description'})}
                keywords={keywords}
                lang={intl.locale}
            />
            <HeroCaseStudy
                heroImage={'case_studies/bysmart/bysmart_header.png'}
                title={'bysmart.title'}
                subtitle1={'bysmart.subtitle'}
                intl={intl}
                color={'linear-gradient(54.46deg, #D1D1D1 30.09%, #A2A2A2 98.52%)'}
                categories={['portfolio.filters.design_dev', 'portfolio.filters.creative_communication', 'portfolio.filters.marketing_strategy']}
            />
            <div className="section-padding">
                <DropBg
                    src={DropBig1}
                    left={'0%'}
                    zIndex={'-1'}
                    bottom={'-20%'}
                    className="d-none d-lg-block"
                />
                <DropBg
                    src={DropUp}
                    bottom={'5%'}
                    left={'25%'}
                    className="d-none d-lg-block"
                />
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle
                                    number={'01'}
                                    text={'bysmart.overview_title'}
                                />
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className={"col-md-5 col-12"}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"bysmart.client_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"bysmart.client_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className={"col-md-6 col-12 offset-md-1"}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"bysmart.client_needs"}/>
                                </Heading>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"bysmart.client_needs_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthVideo
                dangerouslySetInnerHTML={{
                    __html: `<video
                                style="width:100%;height:100%;object-fit:cover"
                                loop
                                muted
                                autoplay
                                playsinline
                                webkit-playsinlines
                                src="${VideoBysmart}"
                             />`
                }}
            />
            <div className="section-padding">
                <DropBg
                    src={DropBig2}
                    right={'-10%'}
                    zIndex={'-1'}
                    top={'15%'}
                    className="d-none d-lg-block"
                />
                <DropBg
                    src={DropBottom}
                    top={'10%'}
                    right={'10%'}
                    className="d-none d-lg-block"
                />
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block">
                                <SectionTitle
                                    number={'02'}
                                    text={'bysmart.brief_title'}
                                />
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading
                                    level={"2"}
                                    margin={'0px'}
                                >
                                    <FormattedMessage id={"bysmart.service_big_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id="bysmart.services_txt_1"/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle
                                    number={'03'}
                                    text={'bysmart.workflow_title'}
                                />
                            </div>
                        </Fade>
                    </div>
                    <div className="row align-items-center">
                        <Fade bottom delay={400}>
                            <div className="col-md-6 col-sm-12">
                                <Heading level={"3"}>
                                    <FormattedMessage id={"bysmart.solutions_big_title"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"bysmart.solutions_head_txt_1"}/>
                                </Paragraph>
                                <Heading level={"4"}>
                                    <FormattedMessage id={"bysmart.solutions_title_1"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"bysmart.solutions_txt_1"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-5 col-sm-12 offset-0 offset-md-1 position-relative">
                                <img
                                    src={BodyGif}
                                    alt={'body Bysmart'}
                                    style={{width: '100%'}}
                                />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div style={{padding: "80px 0"}}>
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <Heading level={"4"}>
                                    <FormattedMessage id={"bysmart.solutions_title_2"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"bysmart.solutions_txt_2"}/>
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <FullWidthImage image={BKGImg} imageWidth={2559} imageHeight={1372}/>
            <div className="section-padding-custom">
                <div className="container">
                    <div className="row align-items-start">
                        <Fade bottom delay={400}>
                            <div className="col-lg-6 mt-5 mt-lg-0 col-12">
                                <Heading level={"4"}>
                                    <FormattedMessage id={"bysmart.solutions_title_3"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"bysmart.solutions_txt_3"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-lg-5 offset-0 offset-lg-1 col-12">
                                <Image
                                    filename={'case_studies/bysmart/smartphone.png'}
                                    alt={'smartphone'}
                                />
                            </div>
                        </Fade>
                    </div>
                    <div className="row align-items-center">
                        <Fade bottom delay={400}>
                            <div className="col-lg-5 col-12 order-2 order-lg-1">
                                <Image
                                    filename={'case_studies/bysmart/cornice.png'}
                                    alt={'smartphone'}
                                />
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-lg-6 offset-0 offset-lg-1 mt-5 mt-lg-0 col-12 order-1 order-lg-2">
                                <Paragraph>
                                    <FormattedMessage id={"bysmart.solutions_txt_4_1"}/>
                                </Paragraph>
                                <Paragraph>
                                    <FormattedMessage id={"bysmart.solutions_txt_4_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Results
                subtitle="bysmart.dark_section_paragraph"
                testimonialQuote="bysmart.dark_section_quote"
                testimonialImg="pasquale_gallo.jpg"
                testimonialName="bysmart.dark_section_testimonial_name"
                testimonialRole="bysmart.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Result1}
                            inView={inViewG1}
                            height="100px"
                            width="122px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={4197273}
                            separator="."
                            headingStyle={{fontWeight: 500}}
                            label="bysmart.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView2}>
                        <LottieController
                            lottieJSON={Result2}
                            inView={inViewG2}
                            height="100px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG2}
                            valueFrom={0}
                            valueTo={4.35}
                            decimals={2}
                            label="bysmart.stats_2"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="bysmart.next_success_case_title"
                desc="bysmart.next_success_case_description"
                link="/case-study/mantegna/"
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "bysmart", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(BysmartCaseStudy))
